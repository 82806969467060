import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { Auth } from 'aws-amplify';
import { setLocalSession } from '@/utils/auth';
import { Warning } from '@/styles/auth.styles';
import { signIn, getSession } from 'next-auth/client';

const CodeConfirmation = ({ email, password }) => {
  const router = useRouter();
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const onConfirmSignup = async e => {
    e.preventDefault();
    await setLoading(true);
    try {
      // confirm with code
      await Auth.confirmSignUp(email, code);
      // now sign in with new user
      // console.log('trying credentials signin');
      // https://next-auth.js.org/getting-started/client#using-the-redirect-false-option
      const signResponse = await signIn('credentials', {
        username: email,
        password,
        redirect: false
      });
      // console.log({ signResponse });
      if (signResponse.error) {
        const respErr = JSON.parse(signResponse.error.replace('Error:', '').trim());
        // console.log({ respErr });
        throw respErr; // throw to catch
      }
      const userSession = await getSession();
      // console.log('pass signin');
      // console.log({ user });
      await setLocalSession(userSession);
      // push to dashboard
      router.push('/dashboard');
    } catch (error) {
      // console.log('error confirming sign up', error);
      // console.log({ err: error.message });
      const errMessage = error.message;
      await setLoading(false);
      await setError(errMessage);
    }
  };

  return (
    <form onSubmit={onConfirmSignup} className="code-confirmation-form">
      <div className="field-wrapper">
        <div className="label header">Code</div>
        <Warning centered>We have sent you a confirmation code to your email</Warning>
        <input
          type="text"
          name="code"
          placeholder="Enter Code"
          autoComplete="off"
          className="app-input"
          aria-label="Enter Code"
          onChange={e => setCode(e.target.value)}
          disabled={loading}
          value={code}
        />
      </div>

      <div className="field-wrapper submit">
        <button type="submit" disabled={loading}>
          {loading ? 'Signin in...' : 'Confirm Sign Up'}
        </button>
      </div>
      {error && (
        <div className="field-wrapper error">
          <Warning>{error}</Warning>
        </div>
      )}
    </form>
  );
};

export default CodeConfirmation;
