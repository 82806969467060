// import { useState } from 'react';
import { FlexBox } from '@/styles/index';
import dynamic from 'next/dynamic';
// import Poll from '@/components/Poll';
const Poll = dynamic(() => import('@/components/Poll'));

const NPSModel = {
  id: 'vbnbvn',
  lang: 'en',
  enableGoBack: false, // default false
  stageNavigation: false, // default false
  showStageNavigation: false, // default true
  // title: 'Preguntas!!',
  // description: 'Cómo estuvo?',
  pages: [
    {
      id: 'page08',
      // title: 'Pag 8',
      // description: 'NPS example',
      elements: [
        {
          id: 'vbvbvbvbvbbv',
          type: 'buttons',
          name: 'buttons-nps',
          title: 'How _likely_ are you to recommend **Pollme** to a friend?',
          cols: 10, // default
          options: [
            { id: 'usd', value: 1 },
            { id: 'uad', value: 2 },
            { id: 'uqd', value: 3 },
            { id: 'urd', value: 4 },
            { id: 'utd', value: 5 },
            { id: 'uhd', value: 6 },
            { id: 'unj', value: 7 },
            { id: 'unk', value: 8 },
            { id: 'unc', value: 9 },
            { id: 'unz', value: 10 }
          ],
          validation: {
            required: { value: true }
          },
          helper: '**1** - Not at all likely; **10** - Extremely likely'
          // labelsBottom: ['Not at all likely', '', 'Extremely likely'],
        },
        {
          id: 'hsdfbkdj',
          type: 'textarea',
          name: 'textarea-nps',
          // title: 'Why {{buttons-nps}}?',
          title: 'Why??',
          validation: {
            required: {
              value: false
            }
            // maxLength: {
            //   value: 140,
            //   message: 'Max 140 caracteres' // JS only: <p>error message</p> TS only support string
            // }
          }
          // isVisible: responses => !!responses['buttons-nps']
        }
      ]
    }
  ]
};
const SignExample = () => (
  <div className="template-wrapper">
    <FlexBox>
      <Poll model={NPSModel} template onEditor cta />
    </FlexBox>
  </div>
);

export default SignExample;
