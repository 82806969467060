import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Auth } from 'aws-amplify';
import Link from 'next/link';
import Image from 'next/image';
import { setLocalSession } from '@/utils/auth';
import { getURL } from '@/utils/index';
import { LoginFormWrapper, Warning } from '@/styles/auth.styles';
import { signIn, getSession, useSession } from 'next-auth/client';
import CodeConfirmationForm from './CodeConfirmation';
// export async function getServerSideProps(context) {
//   console.log('server session');
//   const session = await getSession(context);
//   console.log({ session });
//   return {
//     props: { session }
//   };
// }

const LoginForm = () => {
  const router = useRouter();
  const [confirmation, setConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [session, sessionLoading] = useSession();
  // console.log({ session, sessionLoading });

  useEffect(() => {
    const sessionLogin = async () => {
      // have session
      // console.log('useeffect signin');
      // console.log({ session });
      await setLocalSession(session);
      // redirect??
      if (router) {
        router.replace('/dashboard');
      }
    };
    if (!sessionLoading && session) {
      sessionLogin();
    }
  }, [session, sessionLoading, router]);

  async function resendConfirmationCode() {
    try {
      await Auth.resendSignUp(email);
      // console.log('code resent successfully');
    } catch (err) {
      console.log('error resending code: ', err);
    }
  }

  const onSubmit = async e => {
    e.preventDefault();
    await setLoading(true);
    await setError('');
    if (!email || !password) {
      await setLoading(false);
      return setError('*Please, fill the required fields');
    }

    try {
      // console.log('trying credentials signin');
      // https://next-auth.js.org/getting-started/client#using-the-redirect-false-option
      const signResponse = await signIn('credentials', {
        username: email,
        password,
        redirect: false
      });
      // console.log({ signResponse });
      if (signResponse.error) {
        const respErr = JSON.parse(signResponse.error.replace('Error:', '').trim());
        // console.log({ respErr });
        throw respErr; // throw to catch
      }
      const userSession = await getSession();
      // console.log('pass signin');
      // console.log({ user });
      await setLocalSession(userSession);
      // redirect??
      router.push('/dashboard');
    } catch (err) {
      // console.log('Failed:');
      // console.log({ err });
      let errMessage = err.message;
      if (err.message === `Incorrect username or password.`) {
        errMessage = `Incorrect username or password.`;
      }
      if (err.message === `User does not exist.`) {
        errMessage = `User does not exist`;
      }
      if (err.code === 'UserNotConfirmedException') {
        await resendConfirmationCode();
        await setLoading(false);
        return setConfirmation(true);
      }
      await setError(errMessage);
      await setLoading(false);
    }
  };

  return (
    <LoginFormWrapper>
      <div className="app-login">
        {!confirmation && (
          <>
            <div className="social-login-buttons">
              <button
                type="button"
                onClick={() => signIn('google', { callbackUrl: `${getURL()}/dashboard` })}
              >
                <div className="social-icon">
                  <Image src="/imgs/social/google.png" alt="google social" width={24} height={24} />
                </div>{' '}
                <div className="social-text">Sign in with Google</div>
              </button>
            </div>
            <div className="auth-separator">or</div>
          </>
        )}
        {confirmation ? (
          <CodeConfirmationForm email={email} password={password} />
        ) : (
          <form onSubmit={onSubmit}>
            <div className="field-wrapper">
              <div className="label">Email</div>
              <input
                type="text"
                name="email"
                placeholder="e.g. email@example.com"
                autoComplete="username"
                className="app-input"
                aria-label="e.g. email@example.com"
                onChange={e => setEmail(e.target.value)}
                disabled={loading}
                value={email}
              />
            </div>
            <div className="field-wrapper">
              <div className="label">Password</div>
              <input
                type="password"
                name="password"
                placeholder="Enter your password"
                autoComplete="current-password"
                className="app-input"
                aria-label="Enter your password"
                onChange={e => setPassword(e.target.value)}
                disabled={loading}
                value={password}
              />
            </div>
            <div className="forgot-password-switch">
              <Link href="/forgot-password">
                <a>Forgot your password?</a>
              </Link>
            </div>
            <div className="field-wrapper submit">
              <button type="submit" disabled={loading}>
                {loading ? 'Signin in...' : 'Sign In'}
              </button>
            </div>

            {error && (
              <div className="field-wrapper error">
                <Warning>{error}</Warning>
              </div>
            )}
          </form>
        )}

        <div className="login-form-switch">
          Don't have an account yet?{' '}
          <Link href="/signup">
            <a>Sign Up</a>
          </Link>
        </div>
      </div>
    </LoginFormWrapper>
  );
};

export default LoginForm;
