import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import LoginForm from '@/components/Auth/LoginForm';
import { ViewWrapper } from '@/styles/index';
import { SignPageWrapper } from '@/styles/auth.styles';
import Navbar from '@/components/Layout/Navbar';
// import Footer from '@/components/Layout/Footer';
import SignPollTemplate from '@/components/Templates/SignTemplate';
// import { useRedirect } from '@/utils/navigation';
import HeadMeta from '@/components/HeadMeta';
import { useSession } from 'next-auth/client';
import dynamic from 'next/dynamic';

const Footer = dynamic(() => import('@/components/Layout/Footer'));

const Login = () => {
  const router = useRouter();
  const [session] = useSession();
  // const [localUser, setLocalUser] = useState(null);

  // useEffect(() => {
  //   const checkIfCurrentUser = async () => {
  //     const localDeviceUser = await getCurrentUser();
  //     if (localDeviceUser) {
  //       await setLocalUser(localDeviceUser);
  //     }
  //   };
  //   checkIfCurrentUser();
  // }, []);

  useEffect(() => {
    // Prefetch the dashboard page
    router.prefetch('/dashboard');
  }, [router]);

  // // console.log({ localUser });
  // if (localUser && Object.keys(localUser).length > 0) {
  //   // console.log('redirect to dashboard');
  //   setGAUserId(localUser.user?.userID);
  //   router.replace('/dashboard');
  // }
  return (
    <ViewWrapper>
      <HeadMeta title="Login – Pollme: Free online Poll and Micro-Survey tool" />
      <Navbar session={session} />
      <SignPageWrapper>
        <div className="inner-wrapper">
          <div className="sign-template">
            <SignPollTemplate />
          </div>
          <LoginForm />
        </div>
      </SignPageWrapper>
      <Footer />
    </ViewWrapper>
  );
};

export default Login;
